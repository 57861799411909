<template>
	<section>
		<QuizHeader :quiz="quiz" />
		<QuizContent
			:quiz="quiz"
			:selectedAnswers="selectedAnswers"
			@addSelectedAnswer="addSelectedAnswer"
		/>
	</section>
</template>

<script>
import QuizHeader from "./QuizHeader.vue";
import QuizContent from "./QuizContent.vue";
import { get } from "@/util/requests/get";
import { post } from "@/util/requests/post";
import { mapGetters } from "vuex";

export default {
	name: "Quiz",
	components: { QuizHeader, QuizContent },
	computed: {
		...mapGetters(["userId", "organization"])
	},
	data() {
		return {
			quiz: null,
			selectedAnswers: {}
		};
	},

	methods: {
		addSelectedAnswer({ id, value }) {
			this.selectedAnswers = {
				...this.selectedAnswers,
				[id]: value
			};
			localStorage.setItem(
				`quizAnswers[${this.$route.query.quiz}]`,
				JSON.stringify(this.selectedAnswers)
			);
		},
		async getQuizContent() {
			try {
				await this.$store.dispatch("setIsLoading", true);
				const {
					data: { data }
				} = await get(`/quiz/${this.$route.query.quiz}`);
				this.quiz = data;
				await this.handleQuizWithLocalStorage(data);
			} catch (error) {
				console.error(error);
			}

			await this.$store.dispatch("setIsLoading", false);
		},
		async getDataFromStorage() {
			return JSON.parse(
				localStorage.getItem(`quizData[${this.$route.query.quiz}]`)
			);
		},
		async saveQuestionId(questionId) {
			localStorage.setItem(
				`quizData[${this.$route.query.quiz}]`,
				JSON.stringify({
					questionId: Number(questionId)
				})
			);

			await this.$router.push({
				query: {
					studentCourse: this.$route.query.studentCourse,
					quiz: this.$route.query.quiz,
					question: questionId
				}
			});
		},

		async handleQuizWithLocalStorage(data) {
			if (
				Number(
					!!localStorage.getItem(
						`quizData[${this.$route.query.quiz}]`
					) === true
				)
			) {
				const quizData = await this.getDataFromStorage();
				if (
					!!this.$route.query.question &&
					Number(quizData.questionId) !==
						Number(this.$route.query.question)
				) {
					await this.saveQuestionId(this.$route.query.question);
				} else {
					await this.saveQuestionId(quizData.questionId);
				}
			} else {
				await this.saveQuestionId(data?.questions[0]?.question.id);
			}

			if (
				localStorage.getItem(`quizAnswers[${this.$route.query.quiz}]`)
			) {
				this.selectedAnswers = JSON.parse(
					localStorage.getItem(
						`quizAnswers[${this.$route.query.quiz}]`
					)
				);
			} else {
				this.selectedAnswers = {};
			}
		}
	},

	async created() {
		await this.getQuizContent();
	}
};
</script>
